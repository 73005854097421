<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Добавить маршрут в договор № {{ item.vccarriercontractsDocnum }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="onClose"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Routes
              :items="routes"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :routes="routes"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllRoutes"
              @onRemove="removeRoute"
            ></Routes>
          </v-tab-item>

          <v-tab-item>
            <AllRoutes
              :items="allRoutes"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :routes="routes"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllRoutes>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import Routes from "./components/Routes";
  import AllRoutes from "./components/AllRoutes";
  import GeneralServices from "@/services/GeneralServices";

  export default {
    name: "AddRoute",
    components: {
      AllRoutes,
      Routes,
    },
    props: {
      collection: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      dateWork: null,
      dialog: true,
      item: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      allRoutes: [],
      routes: [],
      startContractDt: null,
      endContractDt: null,
      contractId: null,
    }),
    created() {
      this.item = this.collection.master.selected;
      this.startContractDt = this.item.vccarriercontractsStartcontractdt;
      this.endContractDt = this.item.vccarriercontractsEndcontractdt;
      this.contractId = this.item.vccarriercontractsId;

      this.dateWork = $utils.formatDate(
        new Date(Date.now()),
        "YYYY-MM-DD HH:mm:ss"
      );

      this.loadingRefs = true;
      this.getList();
      this.loadingRefs = false;      
    },
    methods: {
      onClose() {
        this.dialog = !this.dialog;
      },
      cancelAdd() {
        this.tab = 0;
      },
      showAllRoutes() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;

        items.forEach((item) => {
          const index = this.routes.findIndex((route) => {
            return route.id === item.id;
          });

          if (index === -1) {
            this.routes.push(item);
          }
        });
      },
      removeRoute(item) {
        this.routes = this.routes.filter((route) => route.id !== item.id);
      },
      confirmAdd() {
        this.loadingConfirm = true;
        this.addRoute(this.routes);
      },
      async getRoutesByContracts(carrierId, dateWork) {
        const requestContracts = {
          type: "core-read",
          query: `sin2:/v:cfec4202-88fc-43b7-9078-2dce2bc580d5/?filter=and(
                  eq(field(".carrier"),param("${carrierId}","id")), 
                  eq(field(".isActiveRoute"),param(true,"boolean")),
                  not(
                    eq(field(\".route.verStatus.Name\"),param(\"Утратил силу\", \"string\"))
                  ),
                  lte(field(".startDt"),var("util.date.truncToDay(dateEnd)")),
                  or(
                  isnull(field(".endDt")),
                  gte(field(".endDt"),var("util.date.truncToDay(dateBegin)")))
                  )`,
        };
        
        
        try {
          const resp = await jet.http.post(requestContracts);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: "Не удалось загрузить список маршрутов",
            color: "warning",
          });
          console.error("AddRoute: getRoutesByContracts()", e);
          return;
        }
      },
      async getList() {
        let routes = [];
        let routeName = "";
        let routeCode = "";
        let versionStatus = "";
        let versionNumber = "";
        let routeId = "";
        let note = "";
        let code = "";

        const carrierId = this.item.vccarriercontractsCarrierid;
        

        const routesByContracts = await this.getRoutesByContracts(
          carrierId,
          this.dateWork
        );

        const data = (routesByContracts && routesByContracts.data) || [];  
        console.log('data', data);
        await Promise.all(
          data.map(async (it) => {
            if (routeId === it[6]) {
              return;
            }

            routeId = it[18];
            routeCode = it[7];
            routeName = it[8];
            note = it[15];
            versionNumber = it[16];
            versionStatus = it[15];
            code = it[3];

            routes.push({
              id: routeId,
              routeName: routeName,
              routeCode: routeCode,
              startDate: GeneralServices.formatDate(this.startContractDt),
              endDate: GeneralServices.formatDate(this.endContractDt),
              versionNumber: versionNumber,
              versionStatus: versionStatus,
              fullName: `${routeCode} ${routeName}`,
              note: "",
              code:code
            });
            
          })
        );
        this.allRoutes = routes;

      },
      async addRoute(paramsArray) {
        let params = [];
        let id = "";
        let servicedroutes = [];

        const data = paramsArray || [];
        await Promise.all(
          data.map(async (it) => {
            id = it.id;
            servicedroutes.push(id);
          })
        );

        let {id: userId, tenant: tenantId} = this.$store.state.profile;
        const optionsQuery = {
          type: "query",
          query:"d16cb8ba-8ffd-4753-89bc-5c7499bfa500.insertservicedroutesintocontract",
          params: {
            servicedroute: servicedroutes.toString(),
            contractid: this.contractId,
            tenantid: tenantId, //this.item._sec_attrsTenantid,
            userid: userId,     //this.item._sec_attrsUserid,
            startdt: this.startContractDt,
            enddt: this.endContractDt,
            note: " "
          },
        };

        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Добавление маршрутов в договор № ${this.item.vccarriercontractsDocnum} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: "Не удалось добавить маршрут",
            color: "warning",
          });
          console.error("AddRoute: addRoute()", e);
          return;
        }
        this.dialog = false;
        this.collection.refresh();
      }
    },
  };
</script>
